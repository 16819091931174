#itemList {
    height: 75%;
    flex: 2;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: scroll;
    box-sizing: border-box;
}

#noItems {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
}

#noItems > h3 {
    width: 60%;
    margin: auto;
}