#loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid cornflowerblue; /* Blue */
    border-radius: 50%;
    margin: 20px;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.loadingPopUpBackground {
    z-index: 10 !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}