#listSelectorRow {
    display: flex;
    background: lightgrey;
    flex-direction: row;
    align-items: center;
    border-bottom: solid thin gray;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

#listSelector {
    margin: 10px;
    height: 30px;
    width: 85%;
}

#shareLists {
    background-color: cornflowerblue;
}

#editLists {
    background-color: #aaf683;
}