#verifyBody {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 200px;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
}

#sendEmail {
    height: 30px;
    width: 175px;
    font-size: 18px;
}

#verifyMessage {
    text-align: center;
}