.App {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

button {
    border-radius: 15px;
}
