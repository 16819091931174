#footer {
    display: flex;
    flex-direction: column;
    height: 90px;
    box-sizing: border-box;
    align-items: center;
    border-top: solid thin black;
    justify-content: space-evenly;
    background: darkgrey;
}

#deleteButtonContainer {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#footer > div > button {
    width: 140px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
}

#deleteCompleted {
    background: #ff9b85;
}

#deleteAll {
    background: #ee6055;
}

#cancel {
    background: lightgrey;
}

#confirm {
    background: #aaf683;
}

#deleteAll:disabled {
    background: lightgrey !important;
}

#deleteCompleted:disabled {
    background: lightgrey !important;
}

#switcherContainer {
    width: 270px;
    height: 40px;
    padding: 0;
    display: flex;
    background: white;
    border-radius: 15px;
    border: solid thin black;
    justify-content: space-between;
}

#switcherContainer > button {
    height: 100%;
    width: 49%;
    background: none;
    border: none;
    font-size: 20px;
}

#switcherContainer > button:disabled {
    background: cornflowerblue;
    color: black;
}

#switcherContainer > button:active {
    color: darkgrey;
}

#signOutRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#signOutButton {
    background: none !important;
    border: none;
    padding: 0 !important;
    height: 20px !important;
    width: 80px !important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}