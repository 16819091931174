#createForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#taskName {
    margin-top: 10px;
    width: 90%;
    height: 35px;
    font-size: 18px;
}

#low {
    background-color: yellow;
}

#medium {
    background-color: orange;
}

#high {
    background-color: red;
}

.priorityButton {
    margin: 10px 4px;
    height: 30px;
    width: 80px;
    font-size: 15px;
}

.priorityButton:disabled {
    border: solid blue;
    font-weight: bold;
    color: black;
}

#addTask {
    width: 255px;
    height: 40px;
    font-size: 20px;
    background: #aaf683;
}

#addTask:disabled {
    background: lightgrey;
}